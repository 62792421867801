import { Col, Container, Row } from 'react-bootstrap';

import MedicareFAQLogo from '../images/medicarefaq/medicarefaq-logo.svg';
import PulseLoader from 'react-spinners/PulseLoader';
import React from 'react';

class Loading extends React.Component {
  render() {
    return (
      <>
        <Container className='common-section loading-sec d-flex justify-content-center'>
          <Row className='align-items-center text-center'>
            <Col xs={12}>
              <div className='loading-sec-wrap'>
                <div>
                  <PulseLoader size={15} color={'#a0aec0'} loading={true} />
                </div>
                <img
                  style={{ marginTop: 0 }}
                  src={MedicareFAQLogo}
                  alt='Medicare img'
                  className='com-img medicareLog'
                />

                <h3>Calculating Your Custom Rate Quote</h3>
                <p
                  className='text-red'
                  style={{ fontSize: 24, fontWeight: 'bolder' }}
                >
                  ONE MOMENT PLEASE
                </p>
                <p
                  style={{
                    color: '#686868',
                    fontWeight: 'bolder',
                    fontSize: 20,
                  }}
                >
                  Average savings{' '}
                  <span style={{ fontWeight: 'bold' }}>$597/year!</span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default Loading;
